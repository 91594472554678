import { useEffect, useState } from "react";

export function useBookingModule() {
    const [isModuleOpen, setIsModuleOpen] = useState(false);

    function closeModule() {
        setIsModuleOpen(false);
        window.history.replaceState({ ...window.history.state, lv_module_open: false }, "", window.location.href);
    }

    function closeModuleFromIframe() {
        window.parent.postMessage("close", process.env.SITE_ORIGIN);
    }

    useEffect(() => {
        function closeHandler(e) {
            const moduleOrigin = new URL(process.env.MODULE_ORIGIN).origin;
            if (e.origin !== moduleOrigin) {
                return;
            }
            if (e.data !== "close") {
                return;
            }
            setIsModuleOpen(false);
            window.history.replaceState({ ...window.history.state, lv_module_open: false }, "", window.location.href);
        }

        window.addEventListener("message", closeHandler);

        return () => {
            window.removeEventListener("message", closeHandler);
        }
    }, [])

    return { isModuleOpen, setIsModuleOpen, closeModule, closeModuleFromIframe };
}