/* weeks */

.weeks {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.week {
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--color-gray);
    display: grid;
    place-items: center;
    margin: 12px 0;
}

.week:nth-child(6n),
.week:nth-child(7n) {
    color: red;
}



/* days */

.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 6px;
}


/* months */

.months {
    display: flex;
    gap: 35px;
}

.monthName {
    text-align: center;
    margin: 12px 0;
    font-weight: 500;
    text-transform: capitalize;
}


/* calendar */

.container {
    position: relative;
}

.arrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.arrows button {
    all: unset;
    cursor: pointer;
    color: var(--color-gray);
    padding: 12px;
}

.arrows button svg {
    width: 19px;
    height: 19px;
}

.arrows button:disabled {
    color: var(--color-lilac);
}

.wrapper {
    background: var(--color-white);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 4px;
    -webkit-appearance: none;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    -webkit-box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    max-width: 995px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg)
    }
}

.loading svg {
    animation: 3s infinite linear spin;
}

.infoText {
    color: var(--color-gray);
    margin: 0;
    padding: 0;
    font-size: 18px;
}

.description {
    font-size: 14px;
    font-weight: 300;
    color: var(--color-gray);
    margin-top: 8px;
    line-height: 1.4;
}



/* day */

.day {
    width: 60px;
    height: 60px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    display: flex;
    background: none;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    justify-content: start;
    padding-top: 13px;
    transition: background .2s ease;
    color: var(--color-gray);
    position: relative;
    -webkit-appearance: none;
    border: 1px solid var(--color-gray-light);
    box-sizing: border-box;
    font-family: Roboto;
    gap: 3px;
}

/* modifiers */
.hovered {
    background: #DFF0FC;
    border: 1px solid #BCE4FF;
}

.cheapest:not(.from):not(.to):not(.inRange):not(.hovered) {
    color: var(--color-lilac-dark);
}

.cheapest:not(.from):not(.to):not(.inRange):not(.hovered):not(:hover) span {
    color: #3bbf56;
}

.disabled {
    cursor: default;
    color: #CFCFCF;
    border: 1px solid #e6e6e6;
}

.cheapest {
    justify-content: start;
}

.not_available {
    background: #E6E6E6;
    border: 1px solid #E6E6E6;
    opacity: 0.5;
    padding-bottom: 9px;
}

.not_available svg {
    width: 15px;
    height: 15px;
}

.from,
.to,
.inRange {
    background: #6FC3FF;
    color: var(--color-white);
    border: 1px solid #6FC3FF;
    opacity: 1;
}

@media (min-width: 769px) {

    .day:hover,
    .cheapest:not(.from):not(.to):not(.inRange):not(.hovered):hover {
        background: #DFF0FC;
        border: 1px solid #BCE4FF;
        color: var(--color-gray);
    }

    .disabled:hover {
        cursor: default;
        color: #CFCFCF;
        border: 1px solid #e6e6e6;
        background: none;
    }

    .inRange:hover,
    .to:hover,
    .from:hover {
        background: #6FC3FF;
        color: var(--color-white);
        border: 1px solid #6FC3FF;
    }
}

@media (max-width: 1000px) {
    .infoText {
        font-size: 16px;
    }

    .description {
        font-size: 13px;
    }
}

.title {
    color: var(--color-gray);
    font-size: 18px;
    font-weight: 500;
}

.day span {
    white-space: nowrap;
    font-size: 11px;
    font-weight: 400;
}

/* modifiers */

@media (max-width: 988px) {
    .day {
        width: 48px;
        height: 48px;
        font-size: 12px;
        padding: 4px;
        padding-top: 8px;
        border-radius: 12px;
    }

    .wrapper {
        max-width: 760px;
    }

    .day span {
        font-size: 9px;
    }

    .wrapper {
        padding: 17px;
    }

    .months {
        gap: 18px;
    }

    .days {
        gap: 3px;
    }

    .not_available svg {
        width: 13px;
        height: 13px;
    }
}

@media (max-width: 767px) {

    .wrapper {
        padding: 12px;
    }

    .days {
        gap: 4px;
    }

    .wrapper {
        max-height: 80vh;
        min-height: 400px;
    }

    .container {
        overflow-y: auto;
        padding: 0;
        padding-right: 1px;
        padding-bottom: 1px;
    }

    .day {
        width: 46px;
        height: 46px;
        font-size: 12px;
        border-radius: 12px;
    }

    .months {
        flex-direction: column;
    }

    .arrows {
        display: none;
    }
}

.days>* {
    position: relative;
}

.days>*::after {
    content: "";
    position: absolute;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px;
}